import React from 'react';
import {HashLink as Link} from 'react-router-hash-link';
import {NavLink} from "react-router-dom";

const Footer = () => {

    return (
        <footer>

            <div id={"footer_wrapper"}>
                <div className={"mention"}>
                    <div>
                        *Offre soumise à conditions avec obligation d’achat du 30/10/2024 au 24/12/2024 inclus. Pour l’achat de 50€ de produits LEGO® City, LEGO® Friends, LEGO® Ninjago ou LEGO® DreamZzz dans l’un des <a href={"https://www.joueclub.fr/contenu/nos-magasins.html"} target={"_blank"}>magasins JouéClub</a> de France Métropolitaine (Corse incluse) et les DROM COM ou sur le site <a href={"https://www.joueclub.fr/"}                                                                                                                                                                                                       target={"_blank"}>www.joueclub.fr</a>, un
                        jouet LEGO® offert aux
                        enfants hospitalisés en partenariat avec l’association Un cadeau pour la vie et un jouet LEGO® offert aux consommateurs éligibles. Le jouet offert aux enfants hospitalisés est un set LEGO® d’une valeur de 9,99€ (prix
                        de vente conseillé). Le jouet offert aux consommateurs éligibles est un set LEGO® 30670 – La promenade en traîneau du Père Noël (jouet non commercialisé). Dans la limite des stocks disponibles. Offre réservée aux 25
                        000 premières transactions éligibles. Voir modalités complètes <a href="/file/Termes%20et%20conditions.pdf" target={"_blank"}>ici</a>.
                        © 2024 The LEGO® Group.
                    </div>
                </div>

                <div className={"coppyright"}>
                    <a href={"https://www.tlcmarketing.com/france/"} target={"_blank"} className={"footer_logo_tlc"}></a>
                    <div className={"content"}>LEGO® and the LEGO® logo are trademarks <br className={"d-lg-none"}/>of the LEGO® Group. ©2024 The LEGO® Group.</div>
                </div>
            </div>
        </footer>

    );
};

export default Footer;
