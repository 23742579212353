import React, {useState, useRef, useEffect} from 'react';
import {Link} from 'react-router-dom';

import {Form} from 'react-bootstrap';
import {useForm} from 'react-hook-form';


import CustomFieldText from '../form/CustomFieldText';

import * as Helper from '../form/form_inscription_helper.jsx';
import * as Pattern from '../form/form_pattern_helper.jsx';

import ReCAPTCHA from "react-google-recaptcha";
import CustomComboBox from "../form/CustomComboBox";

import Contact from "./contact";
import CustomUploadField from "../form/CustomUploadField";
import BirthDayField from "../form/BirthDayField";

const JeParticipeForm = (props) => {

    const {register, handleSubmit, errors, clearError, setError, setValue} = useForm({validateCriteriaMode: "all"});
    const recaptchaRef = useRef(null);
    const submitRef = useRef(null);
    const [file, setFile] = useState(null);
    const [file2, setFile2] = useState(null);

    function setFilename(event,fieldname,setFileHandle) {

        if (event.target.files[0] !== undefined && Helper.checkIsUploadable(event.target.files[0])) {
            setValue(fieldname, event.target.files[0].name);
            setFileHandle(event.target.files[0]);
            clearError(fieldname);
        } else if (!Helper.checkIsUploadable(event.target.files[0])) {
            setError(fieldname, "filesize", Helper.InvalidErrorMessages.filesize);
        }
    }

    //form validation
    const onSubmit = data => {

        submitRef.current.setAttribute("disabled", "disabled");

        //controle recaptcha
        document.getElementById("recaptcha_error").innerHTML = " ";
        const recaptcha = recaptchaRef.current.getValue();

        //check recaptcha
        if (recaptcha.length === 0) {
            document.getElementById("recaptcha_error").innerHTML = Helper.EmptyErrorMessages.recaptcha;
            submitRef.current.removeAttribute("disabled");
            return false;
        }

        let formData = new FormData();
        formData.append('form', JSON.stringify(data));
        formData.append("ticket", file);
        formData.append("cb", file2);
        formData.append("recaptcha", recaptcha);

        fetch(process.env.REACT_APP_API_URL + '/submit_form.php',
            {
                method: 'POST',
                body: formData
            })
            .then(response => response.json())
            .then(json => {

                if (json.success === true) {
                    window.scrollTo(0, 0);
                    props.setDotation(json.dotation)
                    props.setShowMerci(true);

                    window.mixpanelhandler.track("Participation Form Submitted",{
                        "Result count" : 1,
                        "Civility / Gender" : data.civilite,
                        "Year of birth" : data.annee_naissance
                    });

                } else {
                    //setFormError(json.error);
                    alert(json.error);

                    submitRef.current.removeAttribute("disabled");
                    window.grecaptcha.reset();
                }

            });

    };

    return (
        <Form id={"form_participe"} onSubmit={handleSubmit(onSubmit)} noValidate={true}>

            <div id={"form_content_wrapper"}>

                <CustomComboBox name="civilite" id="civilite" label={"Civilité*"} placeholder={"Choix"}
                                options={[
                                    [
                                        "Madame",
                                        "Madame"
                                    ],
                                    [
                                        "Monsieur",
                                        "Monsieur"
                                    ],
                                    [
                                        "Ne souhaite pas se prononcer",
                                        "Ne souhaite pas se prononcer"
                                    ]
                                ]}
                                register={register({required: {value: true, message: Helper.EmptyErrorMessages.civilite}})}
                                error={errors.civilite && errors.civilite.message}/>

                <CustomFieldText name="prenom" label="Prénom*" register={register({
                    required : {value: true, message: Helper.EmptyErrorMessages.prenom},
                    pattern  : {value: Pattern.alphaPattern, message: Helper.InvalidErrorMessages.prenom},
                    minLength: {value: 2, message: Helper.InvalidErrorMessages.prenom}
                })} error={errors.prenom && errors.prenom.message}/>
                <CustomFieldText name="nom" label="Nom*" register={register({
                    required : {value: true, message: Helper.EmptyErrorMessages.nom},
                    pattern  : {value: Pattern.alphaPattern, message: Helper.InvalidErrorMessages.nom},
                    minLength: {value: 2, message: Helper.InvalidErrorMessages.nom}
                })} error={errors.nom && errors.nom.message}/>

                <BirthDayField
                    label={"Date de naissance<span>*</span>"}
                    register={
                        register({
                            required: true,
                            validate: Helper.checkBirthDate
                        })}
                    error={[
                        (errors.jour_naissance && errors.jour_naissance.type === "required" && Helper.EmptyErrorMessages.date_naissance) ||
                        (errors.mois_naissance && errors.mois_naissance.type === "required" && Helper.EmptyErrorMessages.date_naissance) ||
                        (errors.annee_naissance && errors.annee_naissance.type === "required" && Helper.EmptyErrorMessages.date_naissance),
                        (errors.jour_naissance && errors.jour_naissance.type === "validate" && Helper.InvalidErrorMessages.date_naissance_age) ||
                        (errors.mois_naissance && errors.mois_naissance.type === "validate" && Helper.InvalidErrorMessages.date_naissance_age) ||
                        (errors.annee_naissance && errors.annee_naissance.type === "validate" && Helper.InvalidErrorMessages.date_naissance_age)

                    ]}/>

                <CustomFieldText name="mail" label="E-mail*"
                                 register={
                                     register({
                                         required: true,
                                         pattern : Pattern.emailPattern,
                                         validate: {
                                             domain: (value) => Helper.checkMailDomain(value)
                                             /*confirm : checkMailConfirm*/
                                         }
                                     })}
                                 error={[
                                     errors.mail && errors.mail.type === "required" && Helper.EmptyErrorMessages.mail,
                                     errors.mail && errors.mail.type === "pattern" && Helper.InvalidErrorMessages.mail,
                                     errors.mail && errors.mail.type === "domain" && Helper.InvalidErrorMessages.mail_domain,
                                     errors.mail && errors.mail.type === "confirm" && Helper.InvalidErrorMessages.mail_dif
                                 ]}/>

                <CustomFieldText name="mail_confirm" label="Confirmation de l'adresse e-mail*"
                                 register={
                                     register({
                                         required: true,
                                         pattern : Pattern.emailPattern,
                                         validate: {
                                             domain : (value) => Helper.checkMailDomain(value),
                                             confirm: Helper.checkMailConfirm
                                         }
                                     })}
                                 error={[
                                     errors.mail_confirm && errors.mail_confirm.type === "required" && Helper.EmptyErrorMessages.mail,
                                     errors.mail_confirm && errors.mail_confirm.type === "pattern" && Helper.InvalidErrorMessages.mail,
                                     errors.mail_confirm && errors.mail_confirm.type === "domain" && Helper.InvalidErrorMessages.mail_domain,
                                     errors.mail_confirm && errors.mail_confirm.type === "confirm" && Helper.InvalidErrorMessages.mail_dif
                                 ]}/>

                <CustomFieldText name="cp" label="Code postal" register={register({
                    required : {value: false, message: Helper.EmptyErrorMessages.cp},
                    pattern  : {
                        value  : Pattern.numberAndAlphaPattern_nospace,
                        message: Helper.InvalidErrorMessages.cp
                    },
                    minLength: {value: 5, message: Helper.InvalidErrorMessages.cp},
                    maxLength: {value: 5, message: Helper.InvalidErrorMessages.cp}
                })} error={errors.cp && errors.cp.message}/>


                <div className={"form_row_wrapper"} id={"form_row_wrapper_produit"}>

                    <div className={"label_wrapper"}>
                        <label>Produit éligible acheté</label>
                    </div>

                    <div className={"input_wrapper"}>
                        <select name={"produit"} id={"produit"} defaultValue="" ref={register({
                            required: {
                                value: false,
                                message: Helper.EmptyErrorMessages.produit
                            }
                        })} required={true}>
                            <option disabled={true} value="">Sélectionner</option>
                            <option disabled={true}>Multipack de cartouches</option>
                            <option disabled={true}>&nbsp;&nbsp;Série 5/8</option>
                            <option value={"PACK PGI-5 BK Twin"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PGI-5 BK Twin</option>
                            <option value={"PACK CLI-8 BK/PC/PM/R/G"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK CLI-8 BK/PC/PM/R/G</option>
                            <option value={"PACK CLI-8 C/M/Y"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK CLI-8 C/M/Y</option>
                            <option disabled={true}>&nbsp;&nbsp;Série 9</option>
                            <option value={"PACK PGI-9 MBK/PC/PM/R/G"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PGI-9 MBK/PC/PM/R/G</option>
                            <option value={"PACK PGI-9 PBK/C/M/Y/GY"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PGI-9 PBK/C/M/Y/GY</option>
                            <option disabled={true}>&nbsp;&nbsp;Série 29</option>
                            <option value={"PACK PGI-29 CMY/PC/PM/R"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PGI-29 CMY/PC/PM/R</option>
                            <option value={"PACK PGI-29 MBK/PBK/DGY/GY/LGY/CO"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PGI-29 MBK/PBK/DGY/GY/LGY/CO</option>
                            <option disabled={true}>&nbsp;&nbsp;Série 35/36</option>
                            <option value={"PACK PGI-35 BK TWIN"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PGI-35 BK TWIN</option>
                            <option value={"PACK PGI-35 BK TRIPLE"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PGI-35 BK TRIPLE</option>
                            <option value={"PACK CLI-36 TWIN"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK CLI-36 TWIN</option>
                            <option disabled={true}>&nbsp;&nbsp;Série 40/41</option>
                            <option value={"PACK PG-40 / CL-41"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PG-40 / CL-41</option>
                            <option disabled={true}>&nbsp;&nbsp;Série 42</option>
                            <option value={"PACK CLI-42"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK CLI-42</option>
                            <option disabled={true}>&nbsp;&nbsp;Série 72</option>
                            <option value={"PACK PGI-72 MBK/C/M/Y/R"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PGI-72 MBK/C/M/Y/R</option>
                            <option value={"PACK PGI-72 PBK/GY/PM/PC/CO"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PGI-72 PBK/GY/PM/PC/CO</option>
                            <option disabled={true}>&nbsp;&nbsp;Série 510/511</option>
                            <option value={"PACK PG-510 / CL-511"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PG-510 / CL-511</option>
                            <option value={"PACK PG-510/CL-511 + PAPIER PHOTO"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PG-510/CL-511 + PAPIER PHOTO</option>
                            <option disabled={true}>&nbsp;&nbsp;Série 520/521</option>
                            <option value={"PACK PGI-520 BK TWIN"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PGI-520 BK TWIN</option>
                            <option value={"PACK CLI-521 C/M/Y"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK CLI-521 C/M/Y</option>
                            <option value={"PACK CLI-521 C/M/Y/BK + PAPIER PHOTO"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK CLI-521 C/M/Y/BK + PAPIER PHOTO</option>
                            <option disabled={true}>&nbsp;&nbsp;Série 525/526</option>
                            <option value={"PACK PGI-525 BK TWIN"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PGI-525 BK TWIN</option>
                            <option value={"PACK PGI-525 PGBK TWIN"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PGI-525 PGBK TWIN</option>
                            <option value={"PACK CLI-526 C/M/Y"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK CLI-526 C/M/Y</option>
                            <option value={"PACK CLI-526 C/M/Y/BK + PAPIER PHOTO"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK CLI-526 C/M/Y/BK + PAPIER PHOTO</option>
                            <option disabled={true}>&nbsp;&nbsp;Série 540/541</option>
                            <option value={"PACK PG-540 / CL-541"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PG-540 / CL-541</option>
                            <option value={"PACK PG-540 / CL-541 + PAPIER PHOTO"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PG-540 / CL-541 + PAPIER PHOTO</option>
                            <option value={"PACK PG-540L / CL-541"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PG-540L / CL-541</option>
                            <option value={"PACK PG-540L / CL-541XL + PAPIER PHOTO"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PG-540L / CL-541XL + PAPIER PHOTO</option>
                            <option value={"PACK PG-540L x2 / CL-541XL"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PG-540L x2 / CL-541XL</option>
                            <option value={"PACK PG-540L x2 / CL-541XL + PAPIER PHOTO"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PG-540L x2 / CL-541XL + PAPIER PHOTO</option>
                            <option value={"PACK PG-540XL / CL-541"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PG-540XL / CL-541</option>
                            <option value={"PACK PG-540XL / CL-541XL + PAPIER PHOTO"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PG-540XL / CL-541XL + PAPIER PHOTO</option>
                            <option disabled={true}>&nbsp;&nbsp;Série 545/546</option>
                            <option value={"PACK PG-545 / CL-546"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PG-545 / CL-546</option>
                            <option value={"PACK PG-545 / CL-546 + PAPIER PHOTO"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PG-545 / CL-546 + PAPIER PHOTO</option>
                            <option value={"PACK PG-545XL / CL-546"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PG-545XL / CL-546</option>
                            <option value={"PACK PG-545XL / CL-546XL + PAPIER PHOTO"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PG-545XL / CL-546XL + PAPIER PHOTO</option>
                            <option value={"PACK PG-545XL x2 / CL-546XL"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PG-545XL x2 / CL-546XL</option>
                            <option value={"PACK PG-545XL x2 / CL-546XL + PAPIER PHOTO"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PG-545XL x2 / CL-546XL + PAPIER PHOTO</option>
                            <option disabled={true}>&nbsp;&nbsp;Série 550/551</option>
                            <option value={"PACK PGI-550/CLI-551 PGBK/C/M/Y/BK/GY"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PGI-550/CLI-551 PGBK/C/M/Y/BK/GY</option>
                            <option value={"PACK PGI-550XL / CLI-551 PGBK/CMYB"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PGI-550XL / CLI-551 PGBK/CMYB</option>
                            <option value={"PACK PGI-550XL BK TWIN"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PGI-550XL BK TWIN</option>
                            <option value={"PACK CLI-551 C/M/Y/BK"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK CLI-551 C/M/Y/BK</option>
                            <option value={"PACK CLI-551 C/M/Y/BK + PAPIER PHOTO"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK CLI-551 C/M/Y/BK + PAPIER PHOTO</option>
                            <option value={"PACK CLI-551XL C/M/Y/BK + PAPIER PHOTO"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK CLI-551XL C/M/Y/BK + PAPIER PHOTO</option>
                            <option disabled={true}>&nbsp;&nbsp;Série 560/561</option>
                            <option value={"PACK PG-560 / CL-561"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PG-560 / CL-561</option>
                            <option value={"PACK PG-560 / CL-561 + PAPIER PHOTO"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PG-560 / CL-561 + PAPIER PHOTO</option>
                            <option value={"PACK PG-560XL / CL-561XL + PAPIER PHOTO"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PG-560XL / CL-561XL + PAPIER PHOTO</option>
                            <option value={"PACK PG-560XL x2 / CL-561XL"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PG-560XL x2 / CL-561XL</option>
                            <option value={"PACK PG-560XL x2 / CL-561XL + PAPIER PHOTO"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PG-560XL x2 / CL-561XL + PAPIER PHOTO</option>
                            <option disabled={true}>&nbsp;&nbsp;Série 570/571</option>
                            <option value={"PACK PGI-570 / CLI-571 PGBK/C/M/Y/BK"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PGI-570 / CLI-571 PGBK/C/M/Y/BK</option>
                            <option value={"PACK PGI-570XL / CLI-571 PGBK/C/M/Y/BK"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PGI-570XL / CLI-571 PGBK/C/M/Y/BK</option>
                            <option value={"PACK PGI-570XL BK TWIN"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PGI-570XL BK TWIN</option>
                            <option value={"PACK CLI-571 C/M/Y/BK"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK CLI-571 C/M/Y/BK</option>
                            <option value={"PACK CLI-571 C/M/Y/BK + PAPIER PHOTO"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK CLI-571 C/M/Y/BK + PAPIER PHOTO</option>
                            <option value={"PACK CLI-571XL C/M/Y/BK + PAPIER PHOTO"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK CLI-571XL C/M/Y/BK + PAPIER PHOTO</option>
                            <option disabled={true}>&nbsp;&nbsp;Série 575/576</option>
                            <option value={"PACK PG-575 / CL-576 + PAPIER PHOTO"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PG-575 / CL-576 + PAPIER PHOTO</option>
                            <option value={"PACK PG-575XL / CL-576XL + PAPIER PHOTO"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PG-575XL / CL-576XL + PAPIER PHOTO</option>
                            <option value={"PACK PG-575XL x2 / CL-576XL"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PG-575XL x2 / CL-576XL</option>
                            <option disabled={true}>&nbsp;&nbsp;Série 580/581</option>
                            <option value={"PACK PGI-580 / CLI-581 PGBK/C/M/Y/BK"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PGI-580 / CLI-581 PGBK/C/M/Y/BK</option>
                            <option value={"PACK PGI-580XL / CLI581 BK/CMYK"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PGI-580XL / CLI581 BK/CMYK</option>
                            <option value={"PACK CLI-581 BK/C/M/Y + PAPIER PHOTO"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK CLI-581 BK/C/M/Y + PAPIER PHOTO</option>
                            <option value={"PACK CLI-581 C/M/Y/BK"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK CLI-581 C/M/Y/BK</option>
                            <option value={"PACK CLI-581XL BK/C/M/Y + PAPIER PHOTO"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK CLI-581XL BK/C/M/Y + PAPIER PHOTO</option>
                            <option value={"PACK CLI-581XXL C/M/Y/BK"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK CLI-581XXL C/M/Y/BK</option>
                            <option disabled={true}>&nbsp;&nbsp;Série 1500</option>
                            <option value={"PACK PGI-1500 BK/C/M/Y"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PGI-1500 BK/C/M/Y</option>
                            <option value={"PACK PGI-1500XL BK TRIPLE PACK"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PGI-1500XL BK TRIPLE PACK</option>
                            <option value={"PACK PGI-1500XL BK/C/M/Y"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PGI-1500XL BK/C/M/Y</option>
                            <option value={"PACK PGI-1500XL BK/C/M/Y + Calculatrice LS100T"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PGI-1500XL BK/C/M/Y + Calculatrice LS100T</option>
                            <option disabled={true}>&nbsp;&nbsp;Série 2500</option>
                            <option value={"PACK PGI-2500 BK/C/M/Y"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PGI-2500 BK/C/M/Y</option>
                            <option value={"PACK PGI-2500XL BK TRIPLE PACK"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PGI-2500XL BK TRIPLE PACK</option>
                            <option value={"PACK PGI-2500XL BK TWIN"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PGI-2500XL BK TWIN</option>
                            <option value={"PACK PGI-2500XL BK/C/M/Y"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PGI-2500XL BK/C/M/Y</option>
                            <option value={"PACK PGI-2500XL BK/C/M/Y + Calculatrice LS100T"}>&nbsp;&nbsp;&nbsp;&nbsp;PACK PGI-2500XL BK/C/M/Y + Calculatrice LS100T</option>
                            <option disabled={true}>Imprimante</option>
                            <option disabled={true}>&nbsp;&nbsp;Série MegaTank G</option>
                            <option value={"PIXMA G1530"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA G1530</option>
                            <option value={"PIXMA G2570"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA G2570</option>
                            <option value={"PIXMA G3570"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA G3570</option>
                            <option value={"PIXMA G3571"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA G3571</option>
                            <option value={"PIXMA G3572"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA G3572</option>
                            <option value={"PIXMA G4570"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA G4570</option>
                            <option value={"PIXMA G550"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA G550</option>
                            <option value={"PIXMA G650"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA G650</option>
                            <option disabled={true}>&nbsp;&nbsp;Série MG25xx</option>
                            <option value={"PIXMA MG2550S Noire"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA MG2550S Noire</option>
                            <option value={"PIXMA MG2555S Noire"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA MG2555S Noire</option>
                            <option disabled={true}>&nbsp;&nbsp;Série MG3650S</option>
                            <option value={"PIXMA MG3650S Noire"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA MG3650S Noire</option>
                            <option value={"PIXMA MG3650S Rouge"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA MG3650S Rouge</option>
                            <option value={"PIXMA MG3650S Blanche"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA MG3650S Blanche</option>
                            <option disabled={true}>&nbsp;&nbsp;Série TR46xx</option>
                            <option value={"PIXMA TR4650 Noire"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA TR4650 Noire</option>
                            <option value={"PIXMA TR4651 Blanche"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA TR4651 Blanche</option>
                            <option disabled={true}>&nbsp;&nbsp;Série TR47xx</option>
                            <option value={"PIXMA TR4750i Noire"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA TR4750i Noire</option>
                            <option value={"PIXMA TR4751i Blanche"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA TR4751i Blanche</option>
                            <option disabled={true}>&nbsp;&nbsp;Série TS33xx</option>
                            <option value={"PIXMA TS3350 Noire"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA TS3350 Noire</option>
                            <option value={"PIXMA TS3351 Blanche"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA TS3351 Blanche</option>
                            <option value={"PIXMA TS3352 Rouge"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA TS3352 Rouge</option>
                            <option value={"PIXMA TS3355 Noire"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA TS3355 Noire</option>
                            <option disabled={true}>&nbsp;&nbsp;Série TS34xx</option>
                            <option value={"PIXMA TS3450 Noire"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA TS3450 Noire</option>
                            <option value={"PIXMA TS3451 Blanche"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA TS3451 Blanche</option>
                            <option value={"PIXMA TS3452 Noire/Blanche"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA TS3452 Noire/Blanche</option>
                            <option disabled={true}>&nbsp;&nbsp;Série TS33xx</option>
                            <option value={"PIXMA TS3550i Noire"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA TS3550i Noire</option>
                            <option value={"PIXMA TS3551i Blanche"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA TS3551i Blanche</option>
                            <option disabled={true}>&nbsp;&nbsp;Série TS51xx</option>
                            <option value={"PIXMA TS5150 Noire"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA TS5150 Noire</option>
                            <option value={"PIXMA TS5151 Blanche"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA TS5151 Blanche</option>
                            <option disabled={true}>&nbsp;&nbsp;Série TS53xx</option>
                            <option value={"PIXMA TS5350a Noire"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA TS5350a Noire</option>
                            <option value={"PIXMA TS5350i Noire"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA TS5350i Noire</option>
                            <option value={"PIXMA TS5351a Blanche"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA TS5351a Blanche</option>
                            <option value={"PIXMA TS5351i Blanche"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA TS5351i Blanche</option>
                            <option value={"PIXMA TS5352a Rose"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA TS5352a Rose</option>
                            <option value={"PIXMA TS5353a Verte"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA TS5353a Verte</option>
                            <option value={"PIXMA TS5355a Noire"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA TS5355a Noire</option>
                            <option disabled={true}>&nbsp;&nbsp;Série TS63xx</option>
                            <option value={"PIXMA TS6345 Noire"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA TS6345 Noire</option>
                            <option value={"PIXMA TS6350a Noire"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA TS6350a Noire</option>
                            <option value={"PIXMA TS6351a Blanche"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA TS6351a Blanche</option>
                            <option disabled={true}>&nbsp;&nbsp;Série TS705a</option>
                            <option value={"PIXMA TS705a Noire"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA TS705a Noire</option>
                            <option disabled={true}>&nbsp;&nbsp;Série TS74xx</option>
                            <option value={"PIXMA TS7450a Noire"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA TS7450a Noire</option>
                            <option value={"PIXMA TS7450i Noire"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA TS7450i Noire</option>
                            <option value={"PIXMA TS7451a Blanche"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA TS7451a Blanche</option>
                            <option value={"PIXMA TS7451i Blanche"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA TS7451i Blanche</option>
                            <option disabled={true}>&nbsp;&nbsp;Série TS83xx</option>
                            <option value={"PIXMA TS8350a Noire"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA TS8350a Noire</option>
                            <option value={"PIXMA TS8351a Blanche"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA TS8351a Blanche</option>
                            <option value={"PIXMA TS8352a Rouge"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA TS8352a Rouge</option>
                            <option disabled={true}>&nbsp;&nbsp;Série TS95xx</option>
                            <option value={"PIXMA TS9550 Noire"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA TS9550 Noire</option>
                            <option value={"PIXMA TS9551C Blanche"}>&nbsp;&nbsp;&nbsp;&nbsp;PIXMA TS9551C Blanche</option>
                        </select>

                        <div className="error">
                            {errors.produit && errors.produit.message}
                        </div>
                    </div>

                </div>


                <CustomComboBox name="lieu_achat" id="lieu_achat" label={"Enseigne dans laquelle vous avez effectué votre achat"}
                                placeholder={"Sélectionner"}
                                options={[
                                    ["Amazon","Amazon"],
                                    ["Auchan","Auchan"],
                                    ["Boulanger","Boulanger"],
                                    ["Bureau Vallée","Bureau Vallée"],
                                    ["Calipage","Calipage"],
                                    ["Canon Store","Canon Store"],
                                    ["Carrefour","Carrefour"],
                                    ["Casino","Casino"],
                                    ["CDiscount","CDiscount"],
                                    ["Cora","Cora"],
                                    ["Cultura","Cultura"],
                                    ["Darty","Darty"],
                                    ["Electro Dépôt","Electro Dépôt"],
                                    ["Fnac","Fnac"],
                                    ["Hyperburo","Hyperburo"],
                                    ["Intermarché","Intermarché"],
                                    ["LDLC","LDLC"],
                                    ["Leclerc","Leclerc"],
                                    ["Office Dépôt","Office Dépôt"],
                                    ["Plein Ciel","Plein Ciel"],
                                    ["Super U / Hyper U","Super U / Hyper U"],
                                    ["Top Office","Top Office"],
                                    ["Autre","Autre"],
                                ]}
                                register={register({
                                    required: {
                                        value: false,
                                        message: Helper.EmptyErrorMessages.lieu_achat
                                    }
                                })}
                                error={errors.lieu_achat && errors.lieu_achat.message}/>

                <CustomUploadField name={"ticket"} label={"Téléchargez la photo de votre <br class='d-none d-lg-block'/>preuve d’achat <br class='d-lg-none'/>en entourant la <br class='d-none d-lg-block'/>date ainsi que le produit<br class='d-lg-none'/> acheté*"}
                                   notice={"Formats acceptés : jpeg,<br class='d-none d-lg-block'/> png, pdf, 7 Mo maximum"}
                                   placeholder={""}
                                   register={register({
                                       required: {
                                           value  : true,
                                           message: Helper.EmptyErrorMessages.ticket
                                       }
                                   })}
                                   cta={"Télécharger"}
                                   error={errors.ticket && errors.ticket.message} onchangeHandler={(e) => setFilename(e,"ticket",setFile)}/>

                <CustomUploadField name={"cb"} label={"Téléchargez la photo du code <br class='d-none d-lg-block'/>barre <br class='d-lg-none'/>préalablement découpée <br class='d-none d-lg-block'/>du produit acheté et, <br class='d-lg-none'/>pour les <br class='d-none d-lg-block'/>imprimantes, une photo du <br class='d-none d-lg-block'/>numéro <br class='d-lg-none'/>de série*"}
                                   notice={"Formats acceptés : jpeg,<br class='d-none d-lg-block'/> png, pdf, 7 Mo maximum"}
                                   placeholder={""}
                                   register={register({
                                       required: {
                                           value  : true,
                                           message: Helper.EmptyErrorMessages.cb
                                       }
                                   })}
                                   cta={"Télécharger"}
                                   error={errors.cb && errors.cb.message} onchangeHandler={ (e) => setFilename(e,"cb",setFile2)}/>


                <div className={"optin_container"}>

                    <div id={"optin_reglement_wrapper"} className="checkbox_wrapper form_row_wrapper">
                        <input type="checkbox" name="optin_reglement" id="optin_reglement" value="1"
                               ref={register({required: true})}/>

                        <label htmlFor="optin_reglement">
                            Je reconnais avoir pris connaissance du <br className='d-lg-none'/>
                            <a href="/file/Règlement.pdf" target={"_blank"}>Règlement</a> du Jeu de l’opération<br className='d-none d-lg-block'/> et en <br className='d-lg-none'/>
                            accepter les termes.*
                        </label>
                        <div className="error text-center">{errors.optin_reglement && Helper.EmptyErrorMessages.optin_reglement}</div>
                    </div>

                    <div id={"optin_partenaire_wrapper"} className="checkbox_wrapper form_row_wrapper">
                        <input type="checkbox" name="optin_partenaire" id="optin_partenaire" value="1" ref={register()}/>

                        <label htmlFor="optin_partenaire">
                            Oui j’accepte de recevoir régulièrement des informations sur les produits, promotions, enquêtes, événements et services Canon.<br/>
                            Si vous souhaitez vous désabonner de la réception de documents marketing, veuillez nous contacter à l'adresse : <a href={"mailto:canon-unsubscribe@promotion-support.com"}>canon-unsubscribe@promotion-support.com</a>.
                        </label>
                    </div>

                    <div id={"optin_tlc_wrapper"} className="checkbox_wrapper form_row_wrapper">
                        <input type="checkbox" name="optin_tlc" id="optin_tlc" value="1" ref={register()}/>

                        <label htmlFor="optin_tlc">
                            Oui j’accepte d’être contacté(e) par TLC Marketing dans le cadre de la présente offre, pour recueillir mon avis sur la promotion commerciale ainsi qu’à des fins d’enquêtes de satisfaction, d’élaboration de statistiques, d’organisation de jeux concours ou d’envoi d’informations par e-mail (1).
                        </label>
                    </div>

                </div>
                <div id={"rgpd_text_wrapper"}>
                    <p>
                        Conformément à la loi Informatique et libertés du 6 janvier 1978 dans sa dernière version en vigueur ainsi que le Règlement UE 2016/679 du 27 avril 2016 publié au Journal Officiel de l’Union Européenne le 4 mai 2016 relatif à la protection des personnes physiques à l'égard du traitement des données à caractère personnel et à la libre circulation de ces données, les informations recueillies sont destinées à TLC Marketing en sa qualité de sous-traitant pour le compte de Canon France.
                    </p>
                    <p>
                        (1) TLC Marketing prend les mesures conformes à l’état de l’art afin d’assurer la sécurité et la confidentialité des données, conformément aux dispositions légales précitées. Vos informations seront conservées jusqu’au 31/05/2027, au plus tard conformément à la réglementation si vous avez coché une case opt-in du formulaire de participation. Autrement, vos données seront supprimées à partir du 31/08/2024. Vous bénéficiez d’un droit d’accès, de rectification, d’opposition, de limitation, d’effacement, de portabilité et d’information concernant les données personnelles vous concernant. Afin d’exercer ces droits, nous vous remercions de bien vouloir adresser votre demande auprès de TLC Marketing France par e-mail à <a href={"mailto:francedata@tlcrewards.com?subject=Mes%20données%20personnelles"}>francedata@tlcrewards.com</a> ou par courrier à l’adresse suivante : TLC Marketing France – Mes données personnelles, 92 avenue de Wagram, 75017 Paris.
                    </p>
                </div>

                <div className={"mandatory"}>
                    *Champs obligatoires. <br className={"d-lg-none"}/>
                    Toute demande incomplète ne pourra être validée et traitée.
                </div>

            </div>

            {/* recaptcha */}
            <div className="text-center" id={"recaptcha_wrapper"}>
                <ReCAPTCHA className="d-inline-block" sitekey="6LevKCQhAAAAAErcCcwZpbzQM4R_JcNDUrml4-2b"
                           ref={recaptchaRef}/>
                <div className="error text-center" id="recaptcha_error">&nbsp;</div>
            </div>

            {/* server error */}
            <div className="text-center">
                <div className={"error text-center mb-3"} id={"global_error"}>
                    {/*error*/}
                </div>
                <button className={"cta red"} id={"submit_cta"} ref={submitRef}>Je valide !</button>
            </div>


        </Form>
    );
};


const JeParticipeMerci = (props) => {

    const [step,setStep] = useState(1);
    const [dropedElementCount,setDropedElementCount] = useState(0);
    const [dragElement, setDragElement] = useState(null);

    const endScreenRef = useRef(null);

    //drag and drop event
    const handleDragStart = (e) => {
        setDragElement(e.target);
        e.target.classList.add("is_drag")
    }

    const handleDragStop = (e) => {
        if(dragElement !== null){
            dragElement.classList.remove("is_drag")
        }
    }

    const handleDragOver = (e) => {
        e.stopPropagation();
        e.preventDefault();
    }

    const handleDrop = () => {
        setDragElement(null);
        if((dropedElementCount+1) === 4){
            endScreenRef.current.classList.add("show");

            setTimeout(() => {
                setStep(step+1)
            }, 2500)
        }
        else{
            setDropedElementCount(dropedElementCount+1);
        }
    }

    const startAnimationTimer = () => {
        setTimeout(() => {
            setStep(5)
        },5000)
    }

    return (
        <>
            {step === 1 &&
                <div id={"bravoStrate1"} className={"strate"}>
                    <a href={"#0"} onClick={() => setStep(2)} className={"cta red"}>on y va !</a>
                </div>
            }
            {step === 2 &&
                <div id={"bravoStrate2"} className={"strate"}>

                    <div className={"end_screen"} ref={endScreenRef}/>

                    <div className={"instruction"}>Mettez les 4 ingrédients<br/> dans le chaudron de Panoramix !</div>

                    <div className={"dropzone"} onDrop={handleDrop} onDragOver={handleDragOver}/>

                    <div id={"potion_wrapper"}>
                        <div id={"potion1"} className={"potion"} draggable={true} onDragEnd={handleDragStop} onDrag={handleDragStart} onTouchMove={handleDragStart} onTouchEnd={handleDragStop}/>
                        <div id={"potion2"} className={"potion"} draggable={true} onDragEnd={handleDragStop} onDrag={handleDragStart}/>
                        <div id={"potion3"} className={"potion"} draggable={true} onDragEnd={handleDragStop} onDrag={handleDragStart}/>
                        <div id={"potion4"} className={"potion"} draggable={true} onDragEnd={handleDragStop} onDrag={handleDragStart}/>
                    </div>
                </div>
            }
            {step === 3 &&
                <div id={"bravoStrate3"} className={"strate"}>
                    <div className={"end_screen"} ref={endScreenRef}/>

                    <div className={"instruction"}>Donnez la potion magique<br/>à Astérix !</div>

                    <div className={"dropzone"} onDrop={handleDrop} onDragOver={handleDragOver}/>

                    <div id={"potion_wrapper"}>
                        <div id={"potion5"} className={"potion"} draggable={true} onDragEnd={handleDragStop} onDrag={handleDragStart}/>
                    </div>
                </div>
            }

            {step === 4 &&
                <div id={"bravoStrate4"} className={"strate"}>
                    {startAnimationTimer()}
                </div>
            }

            {step === 5 &&
                <div id={"bravoStrate5"} className={"strate"}>
                    <div id={"dotation"} className={props.dotation}/>
                </div>
            }
        </>
    );
};

const JeParticipe = () => {

    const [showMerci, setShowMerci] = useState(false);
    const [dotation, setDotation] = useState("");

    return (
        <>
            {!showMerci &&
                <div id={"participeStrate1"} className={"strate"}>
                    <h1>Je participe</h1>

                    <div className={"subtitle"}>
                        Complétez le formulaire ci-dessous puis <br className={"d-lg-none"}/>
                        jouez avec Astérix <br className={"d-lg-block d-none"}/>pour tenter de gagner <br className={"d-lg-none"}/>
                        <b>1 séjour au Parc Astérix, un lot de <br className={"d-lg-none"}/>
                            4 entrées</b> <br className={"d-lg-block d-none"}/>ou <b>1 activité loisir !</b>
                    </div>


                    <JeParticipeForm setShowMerci={setShowMerci} setDotation={setDotation} mixpanel={window.mixpanelhandler}/>
                </div>
            }

            {showMerci && <JeParticipeMerci dotation={dotation}/>}

            <Contact/>

        </>
    );
};

export default JeParticipe;
