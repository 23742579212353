import React from 'react';

const Contact = () => {

  return (
    <>
      <div id="contactStrate" className={"strate"}>
        <h1>Vous avez des <br className={"d-lg-none"}/>questions ou besoin<br className={"d-none d-lg-block"}/> de <br className={"d-lg-none"}/>plus d’informations ?</h1>
        <div className={"subtitle"}>Le Service Consommateurs de TLC<br className={"d-lg-none"}/> Marketing reste à votre disposition :</div>

        <div id={"contact_item_wrapper"}>

          <div className={"contact_item"} id={"contact_item_phone"}>
            <div className={"content"}>
              <div className={"title"}>Par téléphone</div>
              <div className={"contact_link"}><a href={"tel:0172060843"}>01 72 06 08 43 </a></div>
              <div className={"detail"}>
                (Appel non surtaxé, prix d’un appel local, <br/>
                tarif en vigueur selon opérateur. Ligne active du lundi <br className={"d-lg-block d-none"}/>au <br className={"d-lg-none"}/>
                vendredi, de 9h30 à 17h30, hors jours fériés)
              </div>
            </div>
          </div>

          <div className={"contact_item"} id={"contact_item_mail"}>
            <div className={"content"}>
              <div className={"title"}>
                Par e-mail à
              </div>
              <div className={"contact_link"}>
                <a href={"mailto:le-super-noel-solidaire-lego@tlcrewards.com?subject=Op%C3%A9ration%20%22Le%20Super%20No%C3%ABl%20Solidaire%20LEGO%C2%AE%22%20%2F%20Vos%20nom%20et%20pr%C3%A9nom"}>le-super-noel-solidaire<br className={"d-lg-none"}/>-lego<br className={"d-lg-block d-none"}/>@tlcrewards.com</a>
              </div>
              <div className={"detail"}>
                (Objet : Opération "Le Super Noël Solidaire LEGO®" /<br/> Vos nom et prénom)
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};


export default Contact;
