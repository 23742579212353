import React from 'react';

//champs texte classique
const CustomFieldText = ({label, name, id=name, placeholder="", type="text", register, error, className=""} ) =>{

	label = label.replace("*","<span>*</span>");

	return(
		<div className={"form_row_wrapper"} id={"form_row_wrapper_"+name}>
			<div className={"label_wrapper"}>
				<label htmlFor={id} dangerouslySetInnerHTML={{__html: label}}/>
			</div>
			<div className={"input_wrapper"}>
				{type === "textarea" && <textarea className={className} type={type} id={id} name={name} placeholder={placeholder} ref={register} maxLength={"250"}/>}
				{type !== "textarea" && <input className={className} type={type} id={id} name={name} placeholder={placeholder} ref={register} />}
				<div className="error">{error}</div>
			</div>
		</div>
	);
}

export default CustomFieldText;