import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Carousel, Modal} from "react-bootstrap";
import Contact from "./contact";
import { WOW } from 'wowjs';

import sapin from "../../images/mobile/sapin/sapin.webm";
import sapinBg from "../../images/mobile/sapin/sapin_bg.webm";
import sapinG from "../../images/mobile/sapin/guirlande.webm";

import sapin_ios from "../../images/mobile/sapin/sapin.mp4";

//import sapin_lottie from "../../images/mobile/sapin/lottie/sapin/sapin.json";

import video_lego from "../../images/video_lego_noel.mp4";
import video_lego_webm from "../../images/video_lego_noel.webm";
import CtaBuy from "../_cta_buy";

const Home = () => {

    const [showModal, setShowModal] = useState(false);
    const [jaugeValue, setJaugeValue] = useState(0);
    const [jaugeValuePercent, setJaugeValuePercent] = useState(0);
    const [jaugeBackgroundPercent, setJaugeBackgroundPercent] = useState(0);
    const objectif = 25000;

    const [pictureTitle, setPictureTitle] = useState("");

    const [hospitals, setHospitals] = useState({});

    const createCarouselDotation = () => {

        let items = [];
        const link = [
            "https://www.joueclub.fr/contenu/boutique-lego-city.html?utm_source=LEGO&utm_medium=iframe_lego_solidaire&utm_campaign=[WEB]NOEL_2024_OPE_LEGO_SOLIDAIRE&utm_content=bottom_page&utm_term=",
            "https://www.joueclub.fr/contenu/boutique-lego-friends.html?utm_source=LEGO&utm_medium=iframe_lego_solidaire&utm_campaign=[WEB]NOEL_2024_OPE_LEGO_SOLIDAIRE&utm_content=bottom_page&utm_term=",
            "https://www.joueclub.fr/contenu/boutique-lego-ninjago.html?utm_source=LEGO&utm_medium=iframe_lego_solidaire&utm_campaign=[WEB]NOEL_2024_OPE_LEGO_SOLIDAIRE&utm_content=bottom_page&utm_term=",
            "https://www.joueclub.fr/contenu/boutique-lego-dreamzzz.html?utm_source=LEGO&utm_medium=iframe_lego_solidaire&utm_campaign=[WEB]NOEL_2024_OPE_LEGO_SOLIDAIRE&utm_content=bottom_page&utm_term=",
        ]

        for (let i = 0; i < 4; i++) {

            items.push(
                <Carousel.Item key={"DotationItem" + i}>
                    <a className={"carousel_dotation_item"} id={"carousel_dotation_item" + (i + 1)} target={"_blank"} href={link[i]}/>
                    <div className={"text-center"}>
                        <a className={"carousel_dotation_link cta yellow mt-3"} target={"_blank"} href={link[i]}>Découvrir</a>
                    </div>
                </Carousel.Item>
            );
        }

        return (
            <div id={"home_dotation_carousel_wrapper"}>
            <Carousel controls={true} interval={2000} indicators={false}>
                    {items}
                </Carousel>
            </div>
        );
    };
    const createCarousel = () => {

        let items = [];

        for (const [key, element] of Object.entries(hospitals)) {

            items.push(
                <Carousel.Item key={"Item" + element.title}>
                    <div className={"carousel_item"} id={"carousel_item" + element.title} year={element.year}>
                        <div className={"picture"} style={{backgroundImage: `url('/photo_association/${element.picture}')`}}/>
                        <div className={"title"}>{element.title}</div>
                        <div className={"description"}>{element.subtitle}</div>
                    </div>
                </Carousel.Item>
            );
        }

        return (
            <div id={"home_carousel_wrapper"}>
                <Carousel controls={true} interval={2000} indicators={false} onSlid={() => {
                    let slide = document.querySelector("#home_carousel_wrapper .active .carousel_item")


                    if(slide.getAttribute("year") == 2023) {
                        console.log("Ce que nous avons réalisé ensemble l’année dernière.")
                        setPictureTitle("Ce que nous avons réalisé ensemble l’année dernière.")
                    }
                    else {
                        console.log("Ce que nous réalisons ensemble cette année.")
                        setPictureTitle("Ce que nous réalisons ensemble cette année.")
                    }

                }}>
                    {items}
                </Carousel>
            </div>
        );
    };

    useEffect(() => {
        new WOW().init()

        //récupération de la qte de cadeau
        fetch("json/data.json").then((r) => r.json())
            .then((data) =>{

                setTimeout(() => {
                    //temps animation 1000ms
                    //50 step de 20ms
                    //15 fait le job

                    var i=1;
                    var id = setInterval(() => {
                        setJaugeValue(Math.round(i * (data.qte/50)));
                        if(i * (data.qte/50) >= data.qte){
                            setJaugeValue(data.qte)
                            clearInterval(id);
                        }
                        i++;
                    }, 15);

                    let value = (data.qte/objectif*96); //95.5 = 100% du sapin rempli

                    if(value > 0){
                        if(value < 25)
                            value += 25;
                        else if(value < 50)
                            value += 20;
                        else if(value < 75)
                            value += 10;
                        else if(value < 90)
                            value += 5;
                    }


                    setJaugeValuePercent(value+"%");
                    setJaugeBackgroundPercent((value+2)+"%");
                },1000);

            })

        /*setTimeout(() => {
            //temps animation 1000ms
            //50 step de 20ms
            //15 fait le job

            var i=1;
            var id = setInterval(() => {
                setJaugeValue(Math.round(i * (objectif/50)));
                if(i * (objectif/50) >= objectif){
                    setJaugeValue(objectif)
                    clearInterval(id);
                }
                i++;
            }, 15);

            let value = 96; //95.5 = 100% du sapin rempli

            //truc de merde parceque je ne connais pas la formule mathématique
            if(value > 0){
                if(value < 25)
                    value += 25;
                else if(value < 50)
                    value += 20;
                else if(value < 75)
                    value += 10;
                else if(value < 90)
                    value += 5;
            }

            setJaugeValuePercent(value+"%");
            setJaugeBackgroundPercent((value+2)+"%");
        },1000);*/

        //récupération de la liste des hopitaux
        fetch("json/hospital.json").then((r) => r.json())
            .then((data) =>{
                //console.log(data)
                setHospitals(data);
            })

        if(sessionStorage.getItem("vi") === null){
            //check pour affichage modal
            setTimeout( () => {
                setShowModal(true);
                sessionStorage.setItem("vi","1")
            },5000)
        }

    },[])

    const playVideo = (e) => {
        console.log("play video ",e.target.id)
        e.target.play()
    }

    const playGuirlandeVideo = () => {
        setTimeout(function(){
            document.getElementById('video_sapin_g').play();
        }, 2000);
    }

    return (
        <>
            <div id={"homeStrate1"} className={"strate"}>
                <p className={"date"}>Du 30/10 au 24/12/2024</p>
                <h1 className={"picture wow slideInLeft"}>Le super Noël solidaire</h1>

                <div className={"operation"}>
                    <a target={"_blank"} href={"https://www.joueclub.fr/contenu/boutique-lego-city.html?utm_source=LEGO&utm_medium=iframe_lego_solidaire&utm_campaign=[WEB]NOEL_2024_OPE_LEGO_SOLIDAIRE&utm_content=top_page&utm_term="} id={"city"}/>
                    <a target={"_blank"} href={"https://www.joueclub.fr/contenu/boutique-lego-friends.html?utm_source=LEGO&utm_medium=iframe_lego_solidaire&utm_campaign=[WEB]NOEL_2024_OPE_LEGO_SOLIDAIRE&utm_content=top_page&utm_term="} id={"friends"}/>
                    <a target={"_blank"} href={"https://www.joueclub.fr/contenu/boutique-lego-ninjago.html?utm_source=LEGO&utm_medium=iframe_lego_solidaire&utm_campaign=[WEB]NOEL_2024_OPE_LEGO_SOLIDAIRE&utm_content=top_page&utm_term="} id={"ninjago"}/>
                    <a target={"_blank"} href={"https://www.joueclub.fr/contenu/boutique-lego-dreamzzz.html?utm_source=LEGO&utm_medium=iframe_lego_solidaire&utm_campaign=[WEB]NOEL_2024_OPE_LEGO_SOLIDAIRE&utm_content=top_page&utm_term="} id={"dreamz"}/>
                    <div className={"santa wow slideInLeft"}/>
                </div>

                <div className={"ope_closed"}>
                    <p>est désormais terminé.</p>
                    <p>Un grand merci à tous<br/> pour vos participations !</p>
                    <p>Grâce à votre générosité, ce sont 30 000 jouets qui ont été envoyés aux enfants hospitalisés pour Noël.</p>
                    <p>Retrouvez dès maintenant toute notre <br className={"d-none d-lg-block"}/> actualité <a href={"https://www.lego.com/fr-fr"} target={"_blank"}>ici</a>.</p>
                </div>

                <div className={"sapin_video_wrapper"}>

                    <div className={"objectif_value"}>
                        <div>
                            <span>OBJECTIF 25 000</span>
                            <span>Jouets offerts</span>
                        </div>
                    </div>

                    {!(/iPhone|iPad|iPod/i.test(navigator.userAgent)) &&
                        <video id="video_sapin_g" src={sapinG} loop={false} muted={true} playsInline={true} onLoadedData={playVideo} onEnded={playGuirlandeVideo}>
                            <source src={sapinG} type={"video/webm"}/>
                        </video>
                    }

                    <div className={"jauge_shadow_wrapper"}/>
                    <div className={"jauge_background_wrapper"} style={{height: jaugeBackgroundPercent}}/>

                    <div className={"jauge_wrapper"} style={{height: jaugeValuePercent}}>
                        {/*<Lottie animationData={sapin_lottie} loop={true} autoplay={true}/>*/}
                        <video id="sapin" loop={true} muted={true} playsInline autoPlay={true} onLoadedData={playVideo}>
                            {!(/iPhone|iPad|iPod/i.test(navigator.userAgent)) && <source src={sapin} type={"video/webm"}/>}
                            <source src={sapin_ios} type={'video/mp4'}/>
                        </video>
                    </div>
                    <div className={"jauge_value"} style={{height: jaugeValuePercent}}>
                        <div>
                            <span>{new Intl.NumberFormat('fr-FR').format(jaugeValue)}</span>
                            {jaugeValue > 1 && <span>Jouets offerts</span>}
                            {jaugeValue <= 1 && <span>Jouet offert</span>}
                        </div>
                    </div>
                    {!(/iPhone|iPad|iPod/i.test(navigator.userAgent)) &&
                        <video id="video_sapin_bg" loop={true} muted={true} playsInline onLoadedData={playVideo}>
                        <source src={sapinBg} type={"video/webm"}/>
                    </video>
                    }
                </div>

                <a target={"_blank"} href={"https://www.joueclub.fr/contenu/noel-solidaire-lego.html?utm_source=LEGO&utm_medium=iframe_lego_solidaire&utm_campaign=[WEB]NOEL_2024_OPE_LEGO_SOLIDAIRE&utm_content=CTA_jachete&utm_term="} className={"cta yellow"}>Acheter et participer</a>

            </div>

            <div id={"homeStrateLivre"} className={"strate"}>
                <div>
                    <div>Nouveauté cette année !</div>
                    <p>Contribuez vous aussi <br className={"d-lg-none"}/>au Retour<br className={"d-none d-lg-block"}/> du Super Noël <br className={"d-lg-none"}/>Solidaire en écrivant <br/>un mot à un enfant <br className={"d-lg-none"}/>hospitalisé.</p>

                    <Link className={"cta yellow"} to={"/livre-or"}>Découvrir le livre d'or</Link>
                </div>
            </div>

            <div id={"homeStrate2"} className={"strate"}>
                <div className={"flocon d-none d-lg-block"}>
                    <div/>
                    <div/>
                </div>
                <h2 className={"picture wow slideInLeft"}>
                    C'est grace à vous.
                </h2>

                <div className={"logo_lego"}>
                    {pictureTitle}
                    <a target={"_blank"} href={"https://www.joueclub.fr/?utm_source=LEGO&utm_medium=iframe_lego_solidaire&utm_campaign=[WEB]NOEL_2024_OPE_LEGO_SOLIDAIRE&utm_content=LOGO_bottom_page&utm_term="}/>
                </div>

                {createCarousel()}

                <Link className={"cta_decouvrir"} to={"/qui-sommes-nous"}>Découvrir l’association</Link>
            </div>
            <div id={"homeStrate3"} className={"strate"}>
                <div className={"flocon d-none d-lg-block"}>
                    <div/>
                    <div/>
                    <div/>
                </div>
                <h2 className={"picture wow slideInLeft"}>Les gammes éligibles</h2>

                {createCarouselDotation()}

                <div id={"cta_ninjago"} className={"gamme d-none d-lg-flex"}>
                    <a href={"https://www.joueclub.fr/contenu/boutique-lego-ninjago.html?utm_source=LEGO&utm_medium=iframe_lego_solidaire&utm_campaign=[WEB]NOEL_2024_OPE_LEGO_SOLIDAIRE&utm_content=bottom_page&utm_term="} target={"_blank"} className={"cta yellow"}>Découvrir</a>
                </div>
                <div id={"cta_city"} className={"gamme d-none d-lg-flex"}>
                    <a href={"https://www.joueclub.fr/contenu/boutique-lego-city.html?utm_source=LEGO&utm_medium=iframe_lego_solidaire&utm_campaign=[WEB]NOEL_2024_OPE_LEGO_SOLIDAIRE&utm_content=bottom_page&utm_term="} target={"_blank"} className={"cta yellow"}>Découvrir</a>
                </div>
                <div id={"cta_friends"} className={"gamme d-none d-lg-flex"}>
                    <a href={"https://www.joueclub.fr/contenu/boutique-lego-friends.html?utm_source=LEGO&utm_medium=iframe_lego_solidaire&utm_campaign=[WEB]NOEL_2024_OPE_LEGO_SOLIDAIRE&utm_content=bottom_page&utm_term="} target={"_blank"} className={"cta yellow"}>Découvrir</a>
                </div>
                <div id={"cta_dreamz"} className={"gamme d-none d-lg-flex"}>
                    <a href={"https://www.joueclub.fr/contenu/boutique-lego-dreamzzz.html?utm_source=LEGO&utm_medium=iframe_lego_solidaire&utm_campaign=[WEB]NOEL_2024_OPE_LEGO_SOLIDAIRE&utm_content=bottom_page&utm_term="} target={"_blank"} className={"cta yellow"}>Découvrir</a>
                </div>
                <CtaBuy/>
            </div>

            {/*<Contact/>*/}

            <Modal className={"modal_video"} show={showModal} onHide={() => setShowModal(false)}>
                <button id={"modal_close"} onClick={() => setShowModal(false)}/>
                <video autoPlay={true} muted={true} playsInline={true} controls={true}>
                    <source src={video_lego_webm} type={"video/webm"}/>
                    <source src={video_lego} type={"video/mp4"}/>
                </video>
            </Modal>
        </>
    );
};


export default Home;
