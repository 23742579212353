import React, {useState, useRef, useEffect} from 'react';

import {useForm} from 'react-hook-form';
import CustomFieldText from "../form/CustomFieldText";
import * as Helper from "../form/form_inscription_helper";
import * as Pattern from "../form/form_pattern_helper";
import {Carousel} from "react-bootstrap";


import frenchBadWords from 'french-badwords-list'
import CtaBuy from "../_cta_buy";
const badwordsCustom = ["teub", "neigre"]

const LivreOr = () => {

    const [messages,setMessages] = useState([])
    const [activeIndex, setActiveIndex] = useState(2);

    const {register, handleSubmit, errors, reset} = useForm();

    const createCarouselAvatar = (register) => {

        let items = [];

        for (let i = 1; i < 9; i=i+3) {

            items.push(
                <Carousel.Item key={"avatarItem" + i}>
                    <div>
                        <div className={"avatar_item"} id={"avatar_item" + i}>
                            <input type={"radio"} name={"avatar"} id={"avatar" + i} value={i} defaultChecked={i===1}/>
                            <label htmlFor={"avatar" + i}></label>
                        </div>

                        <div className={"avatar_item"} id={"avatar_item" + (i+1)}>
                            <input type={"radio"} name={"avatar"} id={"avatar" + (i+1)} value={(i+1)}/>
                            <label htmlFor={"avatar" + (i+1)}></label>
                        </div>

                        <div className={"avatar_item"} id={"avatar_item" + (i+2)}>
                            <input type={"radio"} name={"avatar"} id={"avatar" + (i+2)} value={(i+2)}/>
                            <label htmlFor={"avatar" + (i+2)}></label>
                        </div>
                    </div>
                </Carousel.Item>
            );
        }

        return (
            <div id={"avatar_carousel_wrapper"}>
                <Carousel controls={true} interval={null} indicators={true}>
                    {items}
                </Carousel>
            </div>
        );
    };

    const onSubmit = (data) => {
        //const filter = new Filter();
        const filter = require('leo-profanity')
        filter.loadDictionary('fr');
        filter.add(frenchBadWords.array);
        filter.add(badwordsCustom);

        const isnt_clean = filter.check(data.message) || filter.check(data.pseudo)
        data.avatar = document.querySelector('input[name="avatar"]:checked').value

        if(isnt_clean){
            alert("Merci de rester courtois !!!")
            return;
        }


        //submitRef.current.setAttribute("disabled", "disabled");

        //controle recaptcha
        /*document.getElementById("recaptcha_error").innerHTML = " ";
        const recaptcha = recaptchaRef.current.getValue();

        //check recaptcha
        if (recaptcha.length === 0) {
            document.getElementById("recaptcha_error").innerHTML = Helper.EmptyErrorMessages.recaptcha;
            submitRef.current.removeAttribute("disabled");
            return false;
        }*/

        let formData = new FormData();
        formData.append('form', JSON.stringify(data));
        formData.append('action', 'livre_dor');
        //formData.append("recaptcha", recaptcha);

        fetch(process.env.REACT_APP_API_URL + '/index.php',
            {
                method: 'POST',
                body: formData
            })
            .then(response => response.json())
            .then(json => {

                //reset form
                reset()

                const index = Object.keys(messages)[Object.keys(messages).length-1]

                const new_messages = Object.assign(messages, {
                    [parseInt(index)+1] : {
                        avatar: data.avatar,
                        message: data.message,
                        pseudo: data.pseudo
                    }
                })

                //add new message to listing and show last page
                setMessages(new_messages)

                //switch to last slide
                const pages = document.querySelectorAll(".pages").length
                setActiveIndex(pages )
            });
    }

    const generateBookPage = (array, size) => {

        array = Object.values(array)

        let result = [];

        for (let i = 0; i < array.length; i += size) {
            let chunk = array.slice(i, i + size);
            result.push(chunk);
        }


        return result.map((item) => {
            return(
                <Carousel.Item>
                    <div className={"pages"}>
                        {item.map((message, key) => {
                            return (
                                <div className={`message_item ${"avatar"+message.avatar} `}>
                                    <div className={`message ${key%2===0?"left":"right"}`}>
                                        <p>{message.message}</p>
                                        <span className={"pseudo"}>{message.pseudo}</span>
                                    </div>
                                </div>
                            )
                        })
                        }

                    </div>
                </Carousel.Item>
            )
        })
    }

    useEffect(() => {

        //récupération de la qte de cadeau
        fetch("json/livre.json").then((r) => r.json())
            .then((data) => {
                setMessages(data)
            })

    },[])


    return (
        <>
            <div id={"livreStrate1"}/>
            <div id={"livreStrate2"}>
                <div id={"form_wrapper"}>
                    <div>
                        <p>Si vous aussi vous voulez participer au Retour de ce Super Noël Solidaire, vous pouvez rédiger un message à un enfant hospitalisé.</p>
                        <p>Tous ces messages seront transmis aux enfants hospitalisés grâce à l’association Un cadeau pour la vie.</p>
                        <p><strong>Merci pour eux !</strong></p>
                    </div>
                    <div>
                        <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
                            <CustomFieldText name="pseudo" label="Pseudo*" register={register({
                                required: {value: true, message: Helper.EmptyErrorMessages.prenom},
                                pattern: {value: Pattern.alphaPattern, message: Helper.InvalidErrorMessages.pseudo},
                                minLength: {value: 2, message: Helper.InvalidErrorMessages.pseudo}
                            })} error={errors.pseudo && errors.pseudo.message}/>

                            <CustomFieldText name="message" label="Message" type={"textarea"} placeholder={"250 caractères maximum"} register={register({
                                required: {value: true, message: Helper.EmptyErrorMessages.message},
                                minLength: {value: 2, message: Helper.InvalidErrorMessages.message},
                                maxLength: {value: 250, message: Helper.InvalidErrorMessages.message}
                            })} error={errors.message && errors.message.message}/>


                            <div id={"form_row_wrapper_avatar"} className={"form_row_wrapper"}>
                                <div className={"label_wrapper"}>
                                    <label>Avatar</label>
                                </div>

                                {createCarouselAvatar(register)}
                            </div>
                            <div className={"text-center text-lg-start"}>
                                <button className={"cta yellow"}>Valider</button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
            <div id={"livreStrate3"}>
                <div id={"livre_carousel_wrapper"}>
                    <Carousel controls={true} interval={null} indicators={true} fade activeIndex={activeIndex} onSelect={(selectedIndex) => setActiveIndex(selectedIndex)}>
                        <Carousel.Item>
                            <div id={"couverture"}/>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className={"pages"}>
                                <div></div>
                                <div className={"message_book"}>
                                    À l’attention de tous les enfants hospitalisés…
                                </div>
                            </div>
                        </Carousel.Item>
                        {generateBookPage(messages,window.matchMedia("(pointer: coarse)").matches?2:4)}
                        <Carousel.Item>
                            <div id={"couverture-end"}/>
                        </Carousel.Item>
                    </Carousel>
                </div>
                <CtaBuy/>
            </div>
        </>
    );
};

export default LivreOr;
