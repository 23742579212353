import React, {useEffect} from 'react';
import {Carousel, Modal} from "react-bootstrap";
import { WOW } from 'wowjs'
import CtaBuy from "../_cta_buy";

const QuiSommesNous = () => {

    const createCarousel = () => {

        let items = [];

        for (let i = 0; i < 6; i++) {

            items.push(
                <Carousel.Item key={"Item" + i}>
                    <div className={"carousel_item"} id={"carousel_item" + (i + 1)}/>
                </Carousel.Item>
            );
        }

        return (
            <div id={"qsn_carousel_wrapper"} >
                <Carousel controls={false} interval={2000} indicators={false} fade>
                    {items}
                </Carousel>
            </div>
        );
    };

    useEffect(() => {
        new WOW().init()
    },[])

    return (
        <>
            <div id={"qsnStrate1"} className={"strate"}>
                <h1>Qui sommes-nous ? <a href={"https://www.uncadeaupourlavie.fr"} target={"_blank"}/></h1>
                <div className={"subtitle"}>
                    L'association <b>Un cadeau pour la vie</b> a été créée en 2008 par Bruno Haccoun,<br className={"d-none d-lg-block"}/> en hommage à sa mère, Nancy Haccoun, qui souhaitait se lancer à la fin<br className={"d-none d-lg-block"}/> de sa carrière dans le bénévolat auprès d’enfants malades, mais qui n’a<br className={"d-none d-lg-block"}/> malheureusement pas pu réaliser ce magnifique projet. Bruno a donc décidé<br className={"d-none d-lg-block"}/> de poursuivre et de porter l’engagement de sa mère en créant cette association.
                </div>

                <div className={"infographie_wrapper"}>
                    <div className={"photo"}/>
                    <div className={"testimony_title"}>Quelle est <br/>notre mission ?</div>
                    <div className={"testimony"}>
                        Un passage à l’hôpital est une expérience <br className={"d-none d-lg-block"}/>particulière et spéciale pour les enfants, quelle que <br className={"d-none d-lg-block"}/>soit leur pathologie. Nous nous sommes donc donné <br className={"d-none d-lg-block"}/>pour mission principale <b>d’aider les enfants et leurs <br className={"d-none d-lg-block"}/>familles à s’acclimater et à mieux gérer cette <br className={"d-none d-lg-block"}/>période</b>. Nous collaborons également avec les cadres <br className={"d-none d-lg-block"}/>de santé et les éducateurs afin de répondre au mieux <br className={"d-none d-lg-block"}/>à leurs demandes spécifiques et ainsi les aider à <br className={"d-none d-lg-block"}/>améliorer le bien-être des enfants, clé déterminante <br className={"d-none d-lg-block"}/>dans le rapport à la maladie et dans la façon dont les <br className={"d-none d-lg-block"}/>enfants vont accepter leurs traitements et se battre <br className={"d-none d-lg-block"}/>pour la guérison.
                    </div>
                    <div className={"infographie"}>
                        <div className={"picture"}>
                            <a href={"/file/Hopitaux%20partenaires.pdf"} target={"_blank"}/>
                        </div>
                        <div className={"list"} id={"list_first"}>
                            <div>
                                <ul>
                                    <li>Rénovation et décoration <br className={"d-lg-none"}/>des services hospitaliers</li>
                                    <li>Organisation d’animations <br className={"d-lg-none"}/>et d’événements <br className={"d-lg-none"}/>(anniversaires, Noël…)</li>
                                    <li>Distribution de cadeaux</li>
                                </ul>
                            </div>
                            <div>
                                {createCarousel()}
                            </div>
                        </div>
                        <div className={"list"} id={"list_second"}>
                            <div>
                                <ul>
                                    <li>Réalisation de rêves d’enfants</li>
                                    <li>Dons et installation de <br className={"d-lg-none"}/>matériel</li>
                                    <li>Attribution de <br className={"d-lg-none"}/>parrains/marraines de cœur</li>
                                </ul>
                            </div>
                            <div className={"list_picture"}></div>
                        </div>
                        <div className={"frame"}>
                            Pour en savoir plus,<br/>soutenir l’association<br/>ou devenir bénévole,<br/><a href={"https://www.uncadeaupourlavie.fr"} target={"_blank"}>rendez-vous ici</a>.
                        </div>
                    </div>
                </div>

                <CtaBuy/>
            </div>
        </>
    );
};


export default QuiSommesNous;
